import React from 'react';
import { useNavigate } from 'react-router-dom';

const Dashboard = ({ userProfile, logout }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/', { replace: true });
  };

  if (!userProfile) {
    return <div>Loading user profile...</div>;
  }

  return (
    <div>
      <h1>Dashboard</h1>
      <p>Welcome, {userProfile.name}!</p>
      <p>Email: {userProfile.email}</p>
      {/* Add more user profile information as needed */}
      <button onClick={handleLogout}>Log Out</button>
    </div>
  );
};

export default Dashboard;