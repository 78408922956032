import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './views/Home';
import Login from './views/Login';
import Dashboard from './views/Dashboard';
import { getToken, removeToken, fetchUserProfile } from './utils/auth';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyToken = async () => {
      if (getToken()) {
        try {
          const profile = await fetchUserProfile();
          setUserProfile(profile);
          setIsAuthenticated(true);
        } catch (error) {
          console.error('Authentication failed:', error);
          setIsAuthenticated(false);
          setUserProfile(null);
        }
      }
      setIsLoading(false);
    };

    verifyToken();
  }, []);

  const login = async () => {
    try {
      const profile = await fetchUserProfile();
      setUserProfile(profile);
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Failed to fetch user profile:', error);
    }
  };
  
  const logout = () => {
    removeToken();
    setIsAuthenticated(false);
    setUserProfile(null);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route 
          path="/login" 
          element={
            isAuthenticated ? (
              <Navigate to="/dashboard" replace />
            ) : (
              <Login login={login} />
            )
          } 
        />
        <Route 
          path="/dashboard" 
          element={
            isAuthenticated ? (
              <Dashboard userProfile={userProfile} logout={logout} />
            ) : (
              <Navigate to="/login" replace />
            )
          } 
        />
      </Routes>
    </Router>
  );
};

export default App;