export const TOKEN_KEY = 'auth_token';

export const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const fetchUserProfile = async () => {
    const token = getToken();
    if (!token) {
      throw new Error('No token found');
    }
  
    const response = await fetch('https://api.example.com/user-profile', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  
    if (response.status === 401) {
      removeToken();
      throw new Error('Token is invalid');
    }
  
    if (!response.ok) {
      throw new Error('Failed to fetch user profile');
    }
  
    return response.json();
  };